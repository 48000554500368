@tailwind base;
@tailwind components;
@tailwind utilities;

.table thead tr th {
  font-size: 0.8rem !important;
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.table thead tr th[title='Toggle SortBy']:hover {
  filter: brightness(0.98);
}

.table th:first-child {
  position: relative;
}

.table:not(.table-zebra) tbody tr:not(:last-child) td,
.table:not(.table-zebra) tbody tr td {
  --tw-border-opacity: 0.1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-bottom-width: 1px;
}
